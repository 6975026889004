// As seen on https://www.w3schools.com/howto/howto_js_mobile_navbar.asp

.topnav {
  background-color: $secondary-background-color;
  overflow: hidden;

  a {
    float: left;
    display: block;
    color: $secondary-text-color;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: $secondary-text-color;
    }


    &.navbar__lang {
      float: right;
    }
  }

  .active {
    color: white;
  }

  .icon {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
