.countdown {
  background-color: $terciary-background-color;
  color: $secondary-text-color;
  display: block;
  font-size: 2.5rem;
  margin: 2rem auto 0 auto;
  text-align: center;
  width: fit-content;

  .simply-section {
    display: inline-block;
    padding: 1rem;
  }
}
