@import "normalize.scss";
@import "~bootstrap/scss/bootstrap.scss";

$primary-text-color: #000;
$secondary-text-color: #FFF;
$terciary-text-color: #969696;

$primary-background-color: #FFF;
$secondary-background-color: #E8031B;
$terciary-background-color: #000;

body {
  background: $primary-background-color;
  color: $primary-text-color;
  font-family: sans-serif;
}

h1, nav, footer {
  text-align: center;
}

img {
  max-width: 100%;
}

main {
  margin: 4rem auto;
  min-height: 42rem;
  padding: 1rem 1rem;
  text-align: center;
  width: 90%;

  h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 2rem;
  }

  a {
    color: $primary-text-color;

    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: $primary-text-color;
    }
  }

  hr {
    border-top: 1px solid #FFFFFF;
    text-align: center;

    &:after {
      content: '§';
      display: inline-block;
      position: relative;
      top: -14px;
      padding: 0 10px;
      background: #FFF;
      color: #8c8b8b;
      font-size: 18px;
      -webkit-transform: rotate(60deg);
      -moz-transform: rotate(60deg);
      transform: rotate(60deg);
    }
  }
}

.small-img {
  a > img {
    width: 25%
  }
}


@import "components.scss"
