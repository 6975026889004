footer {
  background-color: $terciary-background-color;
  color: $secondary-text-color;
  margin-top: 210px;

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: $secondary-text-color;
    }
  }

  .contact {
    padding-top: 2rem;
  }

  .social {
    padding-top: 2rem;

    a {
      padding: 0 1rem;
    }

    margin-bottom: 1rem;
  }

  .disclaimer {
    color: $terciary-text-color;
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: left;
    padding-left: 1rem;
    margin-block-end: 0;
  }

  .privacy {
    color: $terciary-text-color;
    padding-top: 1rem;
    text-align: right;
    padding-right: 1rem;
    margin-block-end: 0;
  }
}
